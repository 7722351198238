<template>
    <div>
    
        <div v-if="isInitializing" class="loading">
            <unc-loader></unc-loader>
        </div>
    
        <div v-show="!isInitializing" class="card animated fadeIn">
            <div class="card-header ch-alt">
                <h2>CAMBIAR DOMINIO</h2>
    
                <ul class="actions">
                    <li><a href="" @click.prevent="$emit('eventToParent','canceled')"><i class="zmdi zmdi-close"></i></a></li>
                </ul>
            </div>
    
            <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
                <unc-loader></unc-loader>
            </div>
            <div v-else-if="!isUsernameChosen" class="card-body card-padding">
                <div>
                    <p>No es posible realizar un cambio de dominio si la persona aún no ha elegido su nombre de Usuario UNC.</p>
                </div>
            </div>
            <div v-else class="card-body card-padding">
                <form @submit.prevent="validateBeforeSubmit">
                    <div style="margin-bottom: 3rem;">
                        <div class="fg-line">
                            <li style="list-style: none;">
                                <label>Actual nombre de usuario y dominio</label>
                                <br>
                                <span>{{ this.person.users[0].userName }}</span>
                            </li>
                        </div>
                    </div>
                    <div style="margin-bottom: 3rem;">
                        <div class="fg-line">
                            <li style="list-style: none;">
                                <label>Nuevo nombre de usuario y dominio</label>
                                <a
                                    style="font-size: 20px;margin-left: 5px;"
                                    data-toggle="popover"
                                    data-placement="right"
                                    tabindex="0"
                                    data-original-title="Más información"
                                >
                                    <i class="zmdi zmdi-lock-outline"></i>
                                </a>
                                <br>
                                <span>{{ item.newUsername }}</span>
                            </li>
                        </div>
                    </div>
                    <div class="form-group" :class="{'has-error': errors.has('reason')}">
                        <div class="fg-line">
                            <label for="reason">Motivo</label>
                            <input type="text" name="reason" v-model="item.reason" v-validate="'required|min:5'" class="form-control" placeholder="Ingresá el motivo del cambio..."/>
                        </div>
                        <small v-if="errors.has('reason') && !errors.first('reason').includes('longitud')" class="help-block">{{errors.first('reason')}}</small>
                        <small v-else-if="errors.has('reason') && errors.first('reason').includes('longitud')" class="help-block">Describe el motivo de forma más extensa</small>
                    </div>

                    <div class="form-group" :class="{'has-error': errors.has('orgunit_id')}">
                        <div class="fg-line">
                            <label>Dependencia del usuario</label>
                            <div class="select">
                                <select
                                    v-model="item.orgunit_id"
                                    id="orgunit_id"
                                    name="orgunit_id"
                                    v-validate="'required'"
                                    class="form-control"
                                >
                                <option value>[Seleccione una opción]</option>
                                <option
                                    v-for="item of orgUnitsTree"
                                    :value="item._id"
                                    :key="item._id"
                                >{{ item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <small v-if="!isPersonInOrgUnit">⚠️ La persona actualmente no pertenece a la dependencia seleccionada, al realizar este cambio, se agregará a dicha persona a la dependencia.</small>
                        <small v-if="errors.has('orgunit_id')" class="help-block">{{errors.first('orgunit_id')}}</small>
                    </div>

                    <div class="form-group" :class="{'has-error': errors.has('uncProfile')}">
                        <div class="fg-line">
                            <label>Rol del usuario</label>
                        </div>
                        <div v-for="(profile, index) of roleOptions" :key="profile.code" class="radio" :class="[ index !== roleOptions.length - 1 ? 'm-b-30': 'm-b-20' ]">
                            <label>
                                <input type="radio" name="uncProfile" v-model="item.uncProfile" :value="profile.code" v-validate="'required'"/>
                                <i class="input-helper"></i>
                                {{ profile.name }}
                            </label>
                        </div>
                        <small v-if="errors.has('uncProfile')" class="help-block">Es requerido seleccionar al menos un rol</small>
                    </div>

                    <div class="form-group" :class="{'has-error': errors.has('resolution')}">
                        <div class="fg-line">
                            <label for="resolution">Resolución o expediente</label>
                            <input type="text" name="resolution" v-model="item.resolution" v-validate="'required'" class="form-control" placeholder="Ingresá el número de resolución o expediente GDE..."/>
                        </div>
                        <small v-if="errors.has('resolution')" class="help-block">Es requerido ingresar el número de resolución o expediente GDE</small>
                    </div>

                    <div class="form-group" :class="{'has-error': errors.has('password')}">
                        <div class="input-group">
                            <div class="fg-line text-left">
                                <label for="password">Contraseña de Administrador</label>
                                <input :type="showPassword ? 'text' : 'password'" v-model="item.password" id="password" name="password" v-validate="'required'" class="form-control" placeholder="Ingresá tu contraseña para confirmar..." value=""> 
                            </div>
                            <span @click.prevent="showPassword = !showPassword" class="input-group-addon last">
                                <i class="zmdi zmdi-hc-2x" :class="{'zmdi-eye-off ': !showPassword, 'zmdi-eye': showPassword}"></i>
                            </span>
                        </div>
                        <small v-if="errors.has('password')" class="help-block text-left">{{ errors.first('password') }}</small>
                    </div>
    
                    <div class="text-right">
                        <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Realizar cambio</button>
                    </div>
                </form>
    
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
        import { swal, HTTPFront } from '../../share.js';
        import { mapState, mapGetters } from 'vuex';
        import membershipTypes from '../../../utils/membership_types';
    
        export default {
            data() {
                return {
                    idSelected: 0,
                    isInitializing: false,
                    item: {
                        newUsername: '',
                        orgunit_id: '',
                        password: '',
                        reason: '',
                        resolution: '',
                        uncProfile: '',                   
                    },
                    loadingAction: false,
                    operation: '',
                    optionsOrgUnits: [],
                    optionsMembership: membershipTypes,
                    orgUnitsTree: [],
                    passwordFieldType: 'password',
                    roleOptions: [],
                    showPassword: false,
                }
            },
            computed: {
                ...mapState([
                    'isLoading'
                ]),
                ...mapGetters([
                    'person',
                    'uncProfiles',
                    'currentUser',
                    'orgUnitsByUser',
                    'memberTypes',
                ]),
                isPersonInOrgUnit() {
                    const personOrgUnits = this.orgUnitsByUser?.map(el => el.orgUnit._id);
                    return this.item.orgunit_id === "" ? true : personOrgUnits.includes(this.item.orgunit_id); 
                },
                isUsernameChosen() {
                    return this.person.users.length > 0;
                }
            },
            components: {
            },
            props: [
                'dataParent'
            ],
            created() {
                //Popover
                $(function () {
                    $('[data-toggle="popover"]').popover({
                        content: '<p><span> El nuevo nombre de usuario y el dominio son determinado por el sistema, no se puede modificar.</span></p>',
                        html : true
                    })
                });
            },
            mounted() {
                this.idSelected = this.dataParent.idSelected;
                this.operation = this.dataParent.operation
                this.isEditable = this.dataParent.isEditable;
                if(this.person.users.length) {
                    this.getData();
                    this.getNewUserData();
                }
            },
            methods: {
                checkAliases(domain) {
                    const aliases = this.person.users[0]?.alias?.filter(a => a.split('@')[1] === domain) ?? [];
                    if(aliases.length > 1) {
                        swal.fire({
                            title: 'Oops! Hubo un inconveniente',
                            text: 'Se produjo un inconveniente con los datos del usuario. Por favor comuníquese con Mesa de Ayuda mediante un ticket para realizar el cambio de dominio.',
                            icon: 'warning',
                            confirmButtonText: 'Volver',
                            allowOutsideClick: false,
                        }).then(() => {this.$emit('eventToParent','canceled')});
                    }
                    return aliases[0]?.split('@') ?? [];

                },
                getNewUserData() {
                    const [currentUsername, currentDomain] = this.person.users[0].userName.split('@');
                    
                    const newDomain = /mi\.unc\.edu\.ar$/.test(currentDomain) ? 'unc.edu.ar' : 'mi.unc.edu.ar';

                    const [aliasUsername, aliasDomain] = this.checkAliases(newDomain);

                    const newUsername = aliasDomain === newDomain ? aliasUsername : currentUsername;

                    const filteredUncProfiles = this.uncProfiles.filter(profile => profile.domain === newDomain);

                    this.roleOptions = filteredUncProfiles;
                    this.item.newUsername = newUsername + '@' + newDomain;

                },
                getData() {
                    let self = this;
                    self.getOrgUnitsTree();
                },
                getOrgUnitsTree() {
                    return HTTPFront.get("/orgUnitsTree/" + this.currentUser.id).then(respuesta => {
                        this.orgUnitsTree = respuesta.data;
                        let orgUnits = [];
                        this.orgUnitsTree.forEach(org => {
                            orgUnits.push(org);
                            orgUnits.push(
                                ...org.orgUnitRel
                                .map(ouRel => ouRel.orgUnitChild)
                                .filter(ou => !orgUnits.find(e => e.code == ou.code))
                            );
                        });
                        orgUnits.sort((a, b) =>
                            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        );
                        this.orgUnitsTree = orgUnits;
                    });
                },
                validateBeforeSubmit(e) {
                    this.$validator.validateAll().then(() => {
                        if (!this.errors.any()) {
                            this.save();
                        } else {
                            console.log('No Validated: ', JSON.stringify(this.errors.items)); 
                        }
                    }).catch(() => {
                        swal.fire({title: 'Algo salió mal', text: 'El cambio de dominio no fue realizado.', icon: 'warning' });
                    });
    
                },
                save() {
                    this.loadingAction = true;
    
                    let self = this;

                    let bodyToSend = {
                        userName: this.currentUser,
                        newUsername: self.item.newUsername,
                        uncId: this.person._id,
                        uncProfile: self.item.uncProfile,
                        orgUnitId: self.item.orgunit_id,
                        reason: self.item.reason,
                        resolution: self.item.resolution,
                        password: self.item.password
                    }
    
                    HTTPFront.put(`/domainChange`, bodyToSend)
                    .then(response => {
                        if (response.status === 200) {
                            // Success
                            swal.fire({title: '', html: `El cambio de dominio fue realizado con éxito`, icon: 'success' });
                            this.$emit('eventToParent','saved');
                        }
                    }).catch(async error => {
                        let status = error?.response && error?.response?.data?.code || error?.response?.status;
                        let message = error?.response && error?.response?.data?.message || 'Servicio temporalmente no disponible';

                        if(status === 401 && message === 'Wrong user or password') {
                            message = 'Tú usuario o contraseña de administrador son incorrectos';
                        } else if(status === 404 && (message === 'Person not found' || message === 'Persona no encontrada' || message === 'Usuario no encontrado' || message === 'Profile not found' || message === 'uncProfile no encontrado')) {
                            message = 'No se ha podido encontrar información sobre la persona en el sistema. Por favor comuníquese con Mesa de Ayuda mediante un ticket para realizar el cambio de dominio.';
                        } else if((status === 422 && message === 'You cannot register a new request. You already have one request pending') || (status === 409 && message === 'Ya existe una solicitud pendiente.')) {
                            message = 'Existe una solicitud de cambio de dominio pendiente para esta persona. Por favor comuníquese con Mesa de Ayuda mediante un ticket para completar o anularla.';
                        } else if((status === 422 && message === 'The new username is the same as the current one.') || (status === 409 && message === 'El nuevo nombre de usuario es igual al actual.')) {
                            message = 'El nuevo nombre de usuario es igual que el anterior. Por favor comuníquese con Mesa de Ayuda mediante un ticket para realizar el cambio de dominio.';
                        } else if((status === 422 && message === 'Nombre de usuario no válido') || (status === 409 && (message.includes('El dominio del nombre de usuario no es válido para el perfil') || message === 'El nombre de usuario no respeta las reglas'))) {
                            message = 'El nombre de usuario o el dominio no es válido. Por favor comuníquese con Mesa de Ayuda mediante un ticket para realizar el cambio de dominio.';
                        } else if(status === 422 && (message === 'You cannot update this request.' || message === 'The request already has that status.')) {
                            message = 'La solicitud de cambio de dominio no pudo ser aprobada. Por favor comuníquese con Mesa de Ayuda mediante un ticket.';
                        } else {
                            message = 'Hubo un error inesperado al realizar el cambio de dominio, para completarlo por favor comuníquese con Mesa de Ayuda mediante un ticket.';
                        }

                        switch (status) {
                            case 401:
                                await swal.fire({title: 'Cambio de dominio no realizado', text: message, icon: 'warning' });
                                break;
                            case 500:
                                await swal.fire({title: 'Algo salió mal', text: message, icon: 'warning' });
                                break;
                            default:
                                await swal.fire({title: 'Cambio de dominio no realizado', text: message, icon: 'warning' });
                                break;
                        }
                    }).finally(() => {
                        this.$emit('eventToParent','canceled');
                        this.loadingAction = false;
                    });
                },
            }
        }
    </script>
    
    <style lang="scss" scoped>
    
        .input-group .fg-line {
            padding: 0;
        }
    
        .input-group .fg-line:after {
            left: 0px;
        }
    
        .input-group .fg-line.fg-toggled:after {
            width: calc(100% - 20px);
        }
    
        .input-group-addon {
            cursor: pointer;
        }
    
    </style>