<template>
  <div>
    <div v-if="isInitializing" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-show="!isInitializing" class="card animated fadeIn">
      <div class="card-header ch-alt">
        <h2>VINCULAR A DEPENDENCIA</h2>

        <ul class="actions">
          <li>
            <a href @click.prevent="$emit('eventToParent','canceled')">
              <i class="zmdi zmdi-close"></i>
            </a>
          </li>
        </ul>
      </div>

      <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
        <unc-loader></unc-loader>
      </div>
      <div v-else class="card-body card-padding">
        <form @submit.prevent="validateBeforeSubmit">
          <div class="form-group" :class="{'has-error': errors.has('orgunit_id')}">
            <div class="fg-line">
              <label>Dependencia</label>
              <div class="select">
                <select
                  v-model="item.orgunit_id"
                  id="orgunit_id"
                  name="orgunit_id"
                  v-validate="'required'"
                  class="form-control"
                >
                  <option value>[Seleccione una opción]</option>
                  <option
                    v-for="item of orgUnitsTree"
                    :value="item._id"
                    :key="item._id"
                  >{{ item.name}}</option>
                </select>
              </div>
            </div>
            <small v-if="errors.has('orgunit_id')" class="help-block">El campo es requerido</small>
          </div>

          <div class="form-group" :class="{'has-error': errors.has('typeMember')}">
            <div class="fg-line">
              <label>Membresía</label>
              <div class="select">
                <select
                  v-model="item.typeMember"
                  id="typeMember"
                  name="typeMember"
                  v-validate="'required'"
                  class="form-control"
                >
                  <option value>[Seleccione una opción]</option>
                  <option v-for="item of optionsMembresia" :value="item.typeMember">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <small v-if="errors.has('typeMember')" class="help-block">El campo es requerido</small>
            <span>La membresía "Administrador" permite a la persona acceder al sistema de Gestión de Usuario UNC.</span><br>
            <span>La membresía "Miembro" solo vincula a la persona a una Dependencia sin que esta pueda acceder al sistema de Gestión de Usuario UNC.</span>
          </div>

          <div class="form-group" :class="{'has-error': errors.has('uncProfiles')}">
            <div class="fg-line">
              <label>Roles</label>
            </div>
            <div v-for="profile of checkboxPerfiles" :key="profile.code" class="checkbox m-b-30">
              <label>
                <input 
                  v-validate="'required'"
                  type="checkbox"
                  name="uncProfiles"
                  id="uncProfiles"
                  v-model="item.uncProfiles"
                  :value="profile.code"
                />
                <i class="input-helper"></i>
                {{ profile.name }}
              </label>
            </div>
            <small style="margin-top: -1rem;" v-if="errors.has('uncProfiles')" class="help-block">Se debe seleccionar al menos un rol</small>
          </div>

          <div class="text-right">
            <div v-if="loadingAction" class="loading">
              <unc-loader></unc-loader>
            </div>
            <div v-else>
              <button
                @click="$emit('eventToParent','canceled')"
                type="button"
                class="btn btn-link btn-sm m-t-10 waves-effect"
              >Atrás</button>
              <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Vincular</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTPFront, swal } from "../../share.js";
import { mapGetters } from "vuex";
import membershipTypes from '../../../utils/membership_types.js';

export default {
  data() {
    return {
      idSelected: 0,
      operation: "",
      isInitializing: false,
      item: {
        orgunit_id: "",
        typeMember: "",
        uncProfiles: []
      },
      personSearch: {},
      optionsOrgUnits: [],
      optionsMembresia: membershipTypes,
      checkboxPerfiles: [],
      orgUnitsTree: [],
      loadingAction: false,
    };
  },
  computed: {
    ...mapGetters(["person", "orgUnitsByAdmin", "currentUser"])
  },
  props: ["dataParent"],
  mounted() {
    
    this.personSearch = this.person;
    this.idSelected = this.dataParent.idSelected;
    this.operation = this.dataParent.operation;
    this.isEditable = this.dataParent.isEditable;
    this.checkboxPerfiles = this.$store.getters.uncProfiles;

    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      self.getOrgUnitsTree();
    },
    validateBeforeSubmit(e) {
      this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            this.save();
          } else {
            console.log("No Validated: ", JSON.stringify(this.errors.items));
          }
        })
        .catch(() => {
          console.log("Error Validated!");
        });
    },
    async save() {
      this.loadingAction = true;

      let self = this;

      let addPerson = {
        personId: self.personSearch._id,
        orgUnitId: self.item.orgunit_id,
        typeMember: self.item.typeMember,
        uncProfiles: self.item.uncProfiles
      };

      try {
        const response = await HTTPFront.post("/addPersonInOrgUnit", addPerson);
        if(response.status === 200) {
          await swal.fire({ 
            title: 'Éxito',
            html: `${this.person.names} ${this.person.lastNames} se ha vinculado satisfactoriamente`,
            icon: "success",
          });
          let self = this;
          self.$emit('eventToParent','saved');
        } else {
          swal.fire({ 
            title: "Error: " + respuesta.status,
            text: respuesta.statusText,
            icon: "error",
          });
        }
      } catch (error) {
        let status = error.response.status;
        let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
        switch (status) {
          case 401:
              swal.fire({ title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
              break;
          case 409:
              swal.fire({ title: 'Algo salió mal', text: 'No se pudo vincular la persona a la Unidad Organizativa', icon: 'warning' });
              break;
          case 500:
              swal.fire({ title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
              break;
          default:
              swal.fire({ title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
              break;
          }
      } finally {
        this.isLoading = false;
        this.isInitializing = false;
        this.loadingAction = false;
      }
    },
    getOrgUnitsTree() {
      return HTTPFront.get("/orgUnitsTree/" + this.currentUser.id).then(respuesta => {
        this.orgUnitsTree = respuesta.data;
        let orgUnits = [];
        this.orgUnitsTree.forEach(org => {
          orgUnits.push(org);
          orgUnits.push(
            ...org.orgUnitRel
              .map(ouRel => ouRel.orgUnitChild)
              .filter(ou => !orgUnits.find(e => e.code == ou.code))
          );
        });
        orgUnits.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        this.orgUnitsTree = orgUnits;
      });
    }
  }
};
</script>

<style lang="scss">
</style>