import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VeeValidate from 'vee-validate';
import { Validator } from "vee-validate";
Vue.use(VeeValidate);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import { createPopper } from '@popperjs/core';
Vue.use(createPopper);

//Plugins
import './../node_modules/jquery/dist/jquery.min.js'
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './../node_modules/bootstrap/dist/js/bootstrap.min.js'

//datePicker
import datePicker from 'vue-bootstrap-datetimepicker';
import './../node_modules/eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css'  
Vue.use(datePicker);

//import './assets/vendors/jquery.easy-pie-chart/dist/jquery.easypiechart.min.js'

//Select
import './assets/vendors/bootstrap-select/dist/js/bootstrap-select.min'
import './assets/vendors/bootstrap-select/dist/css/bootstrap-select.min.css'

import swal from './../node_modules/sweetalert2/dist/sweetalert2.min.css'
import './../node_modules/animate.css/animate.min.css'
//import './../node_modules/jquery.nicescroll/dist/jquery.nicescroll.min.js'

//Fonts
import './../node_modules/roboto-fontface/css/roboto/roboto-fontface.css'
import './../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'

//Custom Style
import '../src/assets/scss/my-listview/my-listview.scss'
import '../src/assets/scss/my-form/my-form.scss'
import '../src/assets/scss/my-modal/my-modal.scss'
import '../src/assets/scss/my-app/my-app.scss'
import '../src/assets/scss/my-view-block/my-view-block.scss'
import '../src/assets/scss/my-menu/my-menu.scss'
import '../src/assets/css/app-core.css'
import '../src/assets/css/app-ecart.css'
import  '../src/assets/css/spinner/spinkit.css'

//JS
import './assets/js/app-core.js'
import UncLoader from '../src/components/utils/UncLoader.vue'

// TODO: cargar el valor de invalidDomains desde variables de entorno
const invalidDomains = 'mi'

//Custom Rules
Validator.extend('isNotYuliEmail', {
  getMessage: (field) => `El Correo Electrónico no puede ser '@unc.edu.ar ni @mi.unc.edu.ar'.`,
  validate: (value) => {
    let domains = invalidDomains.split(',')
    domains = domains.filter(e => !e.includes('.')).map(e => `${e}.`)
    return !new RegExp(`.*@((${domains.join('|')}))?unc.edu.ar$`).test(value)
  }
});

Validator.extend('isYuliEmail', {
  getMessage: (field) => `El Correo Electrónico debe ser '@unc.edu.ar o @mi.unc.edu.ar'.`,
  validate: (value) => {
    if ( /^(?!.*@unc\.edu\.ar).*$/.test(value) && /^(?!.*@mi\.unc\.edu\.ar).*$/.test(value) ) {
      return false;
    } else {
      return true;
    }
  }
});

const dictionary = {
  en: {
      messages: {
          required: (field) => 'El campo es requerido.',
          email: (field) => 'El campo debe ser un correo electrónico válido.',
          is: (field) => 'Los campos no coinciden.',
          min: (field, length) => 'La longitud del campo debe ser mayor a ' + length
      }
  }
};

// Override and merge the dictionaries
Validator.localize(dictionary);

Vue.component('unc-loader',UncLoader)

Vue.config.productionTip = false

Vue.filter('toTitleCase', function (value) {
  let newValue = '';
  try {
      if (value) {
        newValue = value.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
      }
  } catch (error) {
    return value;
  }
  return newValue;
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
