<template>
  <div>
    <div v-if="isInitializing" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-show="!isInitializing" class="card animated fadeIn">
      <div class="card-header " :class="this.person.state.code === 'A' ? 'bgm-green' : 'bgm-gray'"><h2>DATOS PERSONALES</h2></div>

      <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
        <unc-loader></unc-loader>
      </div>
      <div v-else class="card-body card-padding">
        <form @submit.prevent="validateBeforeSubmit">
          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <label>Nombres:</label>&nbsp;
              <span>{{ item.nombre }}</span>
            </div>
          </div>

          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <label>Apellido:</label>&nbsp;
              <span>{{ item.apellido }}</span>
            </div>
          </div>

          <div class="fg-line">
            <div v-for="doc of this.item.identities" :key="doc.identityNumber">
              <div class="form-group-no-edit-margin">
                <label class>{{ doc.identityType.split('_')[1] }}:</label>&nbsp;
                <span>{{ doc.identityNumber }}</span>
              </div>
            </div>
          </div>

          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <label>Correo Electrónico:</label>&nbsp;
              <span>{{ item.correoElectronico }}</span>
            </div>
          </div>

          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <label>Estado:</label>&nbsp;
              <span>{{ item.estado }}</span>
            </div>
          </div>

          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <label>Perfil:</label>&nbsp;
              <span>{{ item.perfil }}</span>
            </div>
          </div>

          <div class="form-group-no-edit-margin">
            <div class="fg-line">
              <div v-for="account in UncAndMiUncUsers" :key="account._id">
                <label>Usuario:</label>&nbsp;
                <span>{{ account.userName }}</span>
              </div>
            </div>
          </div>

          <div class="fg-line">
            <label>Dependencias:</label>&nbsp;
            <div v-for="dependency of this.orgUnitsByUser" :key="dependency.code">
              <div class="form-group-no-edit-margin" style="text-indent: 10px;">
                <label>- {{ dependency.shortName }}</label>&nbsp;
                <span>({{ dependency.name }})</span>
              </div>
            </div>
          </div>

          <div class="text-right">
              <button
              @click="$emit('eventToParent','canceled')"
              type="button"
              class="btn btn-link btn-sm m-t-10 waves-effect"
              >Volver</button>
              <button
                v-if="isEditable"
                type="submit"
                class="btn btn-primary btn-sm m-t-10 waves-effect"
              >Vincular</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { HttpYULI, swal, moment } from "../../share.js";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      idSelected: 0,
      operation: "",
      isEditable: true,
      isInitializing: true,
      item: {
        nombre: "",
        apellido: "",
        identities: [],
        correoElectronico: "",
        estado: "",
        perfil: "",
        users: []
      },
      optionsDependencias: null,
      loadingAction: false,
    };
  },
  computed: {
    ...mapGetters(["person", "orgUnitsByUser"]),

    UncAndMiUncUsers() {
      return this.item.users.filter(
        user =>
          user.userName.includes("@unc") || user.userName.includes("@mi.unc")
      );
    }
  },
  components: {
  },
  /* props: ["dataParent"], */
  mounted() {
    /*     $("#fechaNacimiento")
      .datetimepicker({ format: "DD/MM/YYYY" })
      .on("dp.change", () => {
        this.item.fechaNacimiento = $("#fechaNacimiento").val();
      }); */

    /*  this.idSelected = this.dataParent.idSelected;
    this.operation = this.dataParent.operation;
    this.isEditable = this.dataParent.isEditable; */
    this.idSelected = this.person._id;
    this.operation = this.person.operation;
    this.isEditable = this.person.isEditable;

    this.isInitializing = false;
    this.getData();
  },
  methods: {
    getData() {
      let self = this;
      self.item.nombre = self.person.names ? self.person.names : "-";
      self.item.apellido = self.person.lastNames ? self.person.lastNames : "-";
      /* self.item.idDependencia = self.person.names; */
      self.item.identities = self.person.identities ? self.person.identities : "-";
      self.item.correoElectronico = self.person.emails ? self.person.emails.mainEmail.mail : "Sin email asociado";
      self.item.estado = self.person.state ? self.person.state.name + " / " + self.person.state.comment : "Sin estado asociado";
      self.item.perfil = self.person.profile ? self.person.profile.name : "Sin perfil asociado";
      self.item.users = self.person.users ? self.person.users : "-";
      this.orgUnitsByUser = this.$store.state.orgUnitsByUser;

      // axios.all([self.getDependencias()])
      // .then(axios.spread(function (responseDependencias) {

      //     self.optionsDependencias = responseDependencias.data;

      //     return true;
      // }))
      // .then(data => {
      //     self.getAlumnos();
      // });
    },
    /*     getUncAndMiUncUsers() {
        if (this.person.users) {
            const users = this.persons.users.filter(user => { (user.userName.contains('@unc') || user.userName.contains('@miunc')) })
            console.log('usuarios filtrados: ', users)
        }
    }, */
    getAlumnos() {
      let self = this;
      if (this.operation != "addItem") {
        HttpYULI.get("alumnos/" + self.idSelected)
          .then(response => {
            if (response.status === 200) {
              self.item = response.data;

              if (self.dataParent.operation != "addItem") {
                /*                 self.item.fechaNacimiento = moment(
                  self.item.fechaNacimiento
                ).format("DD/MM/YYYY"); */
              }
            } else {
              swal.fire({
                title: "Error: " + response.status,
                text: response.statusText,
                icon: "error"
              });
            }
          })
          .catch(e => {
            console.log("Error HttpYULI GET alumnos: " + e);
          })
          .finally(() => {
            self.isInitializing = false;
          });
      } else {
        self.isInitializing = false;
      }
    },
    getDependencias() {
      return HttpYULI.get("dependencias");
    },
    validateBeforeSubmit(e) {
      this.$validator
        .validateAll()
        .then(() => {
          if (!this.errors.any()) {
            this.save();
          } else {
            console.log("No Validated: ", JSON.stringify(this.errors.items));
          }
        })
        .catch(() => {
          console.log("No Validated!");
        });
    },
    save() {
      this.loadingAction = true;

      let self = this;

      if (this.operation != "addItem") {
        HttpYULI.put("alumnos/" + self.idSelected, this.item)
          .then(response => {
            if (response.status === 204 || response.status === 200) {
              self.$emit("eventToParent", "saved", self.idSelected);
            } else {
              swal.fire({
                title: "Error: " + response.status,
                text: response.statusText,
                icon: "error"
              });
            }
          })
          .catch(e => {
            console.log("Error HttpYULI POST: " + e);
          }).finally(() => {
            this.loadingAction = false;
          });
      } else {
        HttpYULI.post("alumnos", this.item)
          .then(response => {
            if (response.status === 201 || response.status === 200) {
              self.idSelected = response.data.id;
              self.$emit("eventToParent", "saved", self.idSelected);
            } else {
              swal.fire({
                title: "Error: " + response.status,
                text: response.statusText,
                icon: "error"
              });
            }
          })
          .catch(e => {
            console.log("Error HttpYULI POST: " + e);
          }).finally(() => {
            this.loadingAction = false;
          });
      }
    }
  }
};
</script>

<style lang="scss">
</style>