import Vue from 'vue'
import Router from 'vue-router'
import authService from './auth/authService';
const defaultPath = '/usuarios';
// It can be customized to provide different paths based on user attributes (e.g. permissions)
const getDefaultPath = user => defaultPath;
const unauthorizedPath = '/no-autorizado';


import UsuariosListPage       from "./components/usuarios/usuario.list.vue";
import MiDependenciaListPage  from "./components/midependencia/midependencia.list.vue";
import NotAuthorized          from './components/not.authorized.vue'

let misRutas = [];
misRutas = [
  {
    path: '/',
    component: () => import('./views/Login.vue'),
    meta: {
        requiresAnnonymousUser: true
    }
  },
  { 
    path: unauthorizedPath,
    name: 'Unauthorized',
    component: () => import('./views/Unauthorized.vue'),
    meta: {
      requiresAnnonymousUser: true
    }
  },
  {
    path: '/permiso-denegado',
    name: 'Forbidden',
    component: () => import('./views/Forbidden.vue'),
  },
  { 
    path: '/usuarios',
    component: UsuariosListPage,
    meta: {} 
  },
  { 
    path: '/midependencia',
    component: MiDependenciaListPage,
    meta: {} 
  },
  {
    path: '*',
    component: () => import('./views/PageNotFound'),
    name: 'NotFound',
  }
];

// By default, all routes require auth
misRutas.forEach(r => {
  if ((!r.meta?.requiresAnnonymousUser && !r.meta?.requiresAuth && !r.meta?.public) || r.meta?.requiredPermissions || r.meta?.requiredRoles) {
      if (!r.meta) {
          r.meta = {};
      }
      r.meta.requiresAuth = true;

      // In case a route is marked as 'requiresAnnonymousUser' or 'public', but also requires permissions or roles
      delete r.meta.requiresAnnonymousUser;
      delete r.meta.public;
  }
});


Vue.use(Router)

const router = new Router({
//   mode: 'history',
  base: process.env.BASE_URL,
  routes: misRutas
});

router.beforeEach((to, from, next) => {
  let ruta = to.path.replace("/","");

  const resolveAuth = to.matched.some(record => record.meta.requiresAuth || record.meta.requiresAnnonymousUser);
  const fn = () => {
    if (resolveAuth && authService.forceLogin && to.path !== '/') {
        return next({ path: '/', query: { t: to.fullPath } });
    }

    if (to.path === '/' && !authService.forceLogin) {
        return next({ path: getDefaultPath(authService.user) });
    }
    if (to.matched.some(record => record.meta.requiresAuth) && !authService.validUser) {
      return next({ name: 'Unauthorized', query: { t: to.fullPath } });
    } 
    else if (to.matched.some(record => record.meta.requiredPermissions || record.meta.requiredRoles) && !authService.user.hasAuthorities(to.matched)) {
      return next({ name: 'Forbidden', query: { t: to.path } });
  }
  next();
};

  if (resolveAuth) {
    authService.login();
  }

  if (!authService.loading) {
    return fn();
  }

  authService.$watch('loading', loading => {
    if (!loading && !authService.error) {
        return fn();
    }
  });
});

export default router;
