<template>
  <div class="container m-t-30">
    <div class="row animated fadeIn">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="jumbotron text-center" style="margin-bottom:10px!important; padding-bottom:25px!important; padding-top:10px!important;">
          <h3>Usuario no autorizado.</h3>
          <p>El usuario no se encuentra autorizado para acceder a esta aplicación. Puedes ponerte en contacto con el administrador de tu dependencia. 
             <br>
          </p>
        </div>

        
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
#sidebar .profile-menu > a {
  display: block;
  height: 129px;
  margin-bottom: 5px;
  width: 100%;
  /*background: url(./assets/img/2019-PI-INFORMATICA-IMAGEN-2048x1642-12.png) left
    top no-repeat;*/
  background-size: 100%;
}
</style>