<template>
<div>
    
    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else-if="showForm" class="animated fadeIn">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <orgunits-form :dataParent="dataForChild" @eventToParent="eventFromChild"></orgunits-form>
            </div>
        </div>
    </div>
    <div v-else-if="!showForm && editMembership" class="animated fadeIn">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
                <usuario-membership :dataParent="membershipInfo" @eventToParent="eventFromChild"></usuario-membership>
            </div>
        </div>
    </div>        

    <div v-else class="row">
    
        <div class="col-md-12 col-sm-12 col-xs-12">
            
            <div class="card">

                <div class="card-header" :class="this.person.state.code === 'A' ? 'bgm-green' : 'bgm-gray'">
                    <h2>{{ listTitle }}</h2>
                    <button v-if="this.person.state.code === 'A' || this.person.state.code === 'V' || this.person.state.code === 'I' " @click.prevent="addItem" class="btn bgm-red btn-float waves-effect" title="Vincular a Dependencia"><i class="zmdi zmdi-plus"></i></button>
                </div>

                 <div class="card-body">
                    <div v-if="asignedOrgUnitsByUser.length === 0" class="jumbotron text-center">
                        <h3>No se han encontrado dependencias vinculadas.</h3>
                    </div>
                    <div v-else class="my-listview">
                        <div class="row my-listview-item hidden-xs">
                            <div class="col-md-6 col-sm-6 col-xs-12"><b>Dependencia</b></div>
                            <div class="col-md-2 col-sm-2 col-xs-12 text-center"><b>Membresía</b></div>
                            <div class="col-md-2 col-sm-2 col-xs-12 text-center"><b>Rol</b></div>
                            <div class="col-md-2 col-sm-2 col-xs-12"></div>
                        </div>

                        <div class="row my-listview-item" v-for="ou in asignedOrgUnitsByUser" :key="ou.orgUnit._id">
                            <div class="col-md-6 col-sm-6 col-xs-12">
                                <h1>{{ ou.orgUnit.name }}</h1><small>{{ ou.orgUnit.shortName }} - Código: {{ ou.orgUnit.code }}</small>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 text-center">
                                <h1>{{ou.typeMemberName}}</h1>
                            </div>
                            <div class="col-md-2 col-sm-2 col-xs-12 text-center">
                                <div v-if="ou.uncProfiles.length !== 0">
                                    <h1 v-for="profile in ou.uncProfiles">
                                        {{profile | toTitleCase}}
                                    </h1>
                                </div>
                                <div v-else>-</div>
                            </div>

                            <div class="col-md-2 col-sm-2 col-xs-12">
                                <ul class="my-actions">
                                    <li v-if="ou.orgUnit.edit"><a href="" @click.prevent="editItem(ou.orgUnit, item._id, ou.typeMember, ou.uncProfiles)" title="Editar Membresía"><i class="zmdi zmdi-edit"></i></a></li>
                                    <li class="my-actions-delete" v-if="ou.orgUnit.edit"><a href="" @click.prevent="deleteItem(ou.orgUnit, item._id)" title="Desvincular de Dependencia"><i class="zmdi zmdi-assignment-return"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        
            <div v-if="isLoading" class="loading">
                <unc-loader></unc-loader>
            </div>

        </div>
        
    </div>

</div>
</template>

<script>
    import { HTTPFront, HttpYULI, swal } from '../../share.js';
    import axios from 'axios';
    import { mapGetters, mapActions } from "vuex";
    import orgunitsForm from './usuario.orgunits.form';
    import usuarioMembership from './usuario.membership';

    export default {
        data() {
            return {
                listTitle: 'DEPENDENCIAS VINCULADAS',
                listItems: [],
                showForm: false,
                isInitializing: true,
                isEditable: true,
                isLoading: false,
                isScrollActived: true,
                item: '',
                query: {
                    order: 'titulo',
                    page: 0,
                    offset: 0,
                    limit: 25
                },
                dataForChild: {
                    idSelected: 0,
                    operation: '',
                    isEditable: true
                },
                asignedOrgUnitsByUser: [],
                canEdit: false,
                editMembership: false,
                membershipInfo: {}
            }
        },
        computed: {
            ...mapGetters(["person","orgUnitsByAdmin"]),
        },        
        props: [
            'dataParent'
        ],
        components: {
            orgunitsForm,
            usuarioMembership
        },
        mounted() {
            this.item = this.person;
            this.isEditable = this.person.isEditable;

            this.isLoading = false;
            this.isInitializing = false;
            this.getData();

        },
        methods: {
            ...mapActions(['setUserOrgUnits']),
            getData() {
                let idPersonaBuscada = this.item._id;
                HTTPFront.get("/orgUnitsByUser/" + idPersonaBuscada).then(respuesta => {
                    if (respuesta.status === 200 && respuesta.data) {
                        this.asignedOrgUnitsByUser = respuesta.data;
                        this.setUserOrgUnits(this.asignedOrgUnitsByUser);
                    }

                    for(let i=0;i<this.asignedOrgUnitsByUser.length;i++){
                        for(let j=0;j<this.orgUnitsByAdmin.length;j++){
                            if(this.asignedOrgUnitsByUser[i].orgUnit.code == this.orgUnitsByAdmin[j].code){
                                this.asignedOrgUnitsByUser[i].orgUnit.edit = true;
                            }
                            /* TODO: OBTENER LOS TIPOS DE MEMBRESÍA DESDE UTILS*/ 
                            switch(this.asignedOrgUnitsByUser[i].typeMember){
                                case 0: 
                                    this.asignedOrgUnitsByUser[i].typeMemberName = 'Miembro'
                                    break;
                                case 1: 
                                    this.asignedOrgUnitsByUser[i].typeMemberName = 'Gestor'
                                    break;
                                case 2: 
                                    this.asignedOrgUnitsByUser[i].typeMemberName = 'Administrador'
                                    break;   
                            }
                        }
                    }
                });  
            },
            addItem() {
                this.dataForChild.idSelected = 0;
                this.dataForChild.parentSelected = this.itemSelected;
                this.dataForChild.operation = "addItem";
                this.dataForChild.isEditable = true;
                this.showForm = true;
            },
            editItem(orgUnit, idPersona, typeMember, uncProfiles) {
                this.membershipInfo = {
                    orgUnit: orgUnit,
                    idPersona: idPersona,
                    typeMember: typeMember,
                    uncProfiles: uncProfiles
                }
                this.showForm = false;
                this.editMembership = true;
            },
            deleteItem(orgUnit, persona) {
                swal.fire({
                    title: "¿Desvincular?",
                    text: "Vas a desvincular a la persona de tu dependencia, aún así, ella podrá seguir accediendo a las aplicaciones UNC.",
                    icon: 'warning',
                    showCancelButton: true,
                    reverseButtons: true,
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Sí",
                })
                .then(async result => {
                    if (result.isConfirmed) {
                        try {
                            const response = await HTTPFront.delete('/orgUnits/'+orgUnit._id+'/members/'+persona);
                            if (response.status === 200) {
                                swal.fire({ title: 'Éxito', html:  'Se ha desvinculado satisfactoriamente', icon: "success", showConfirmButton: false });
                                this.getData();
                            }
                        } catch(error) {
                            let status = error.response.status;
                            let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
                            switch (status) {
                                case 401:
                                    swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                                    break;
                                case 409:
                                    swal.fire({title: 'Solicitud no realizada', text: 'No se pudo desvincular la persona de la unidad organizativa.', icon: 'warning' });
                                    break;
                                case 500:
                                    swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                                    break;
                                default:
                                    swal.fire({title: 'Solicitud no realizada', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                                    break;
                            }
                        }
                    }
                })
            },
            eventFromChild(action = '', params = null){
                switch (action) {
                    case 'canceled':
                        this.showForm = false;
                        this.editMembership = false;
                    break;

                    case 'saved':
                        this.showForm = false;
                        this.editMembership = false;
                        this.getData();
                    break;
                }
            }
        }
    }
</script>

<style lang="scss">
    
</style>