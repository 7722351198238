<template>
  <div>
    <div v-if="hasInitialized" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-else>
      <div v-if="isPendingData">
        <div class="col-md-3 col-sm-3"></div>
        <div class="col-md-9 col-sm-12 col-xs-12">
          <usuario-pending-menu @eventToParent="eventFromChild"></usuario-pending-menu> 
        </div>
      </div>  
      <div v-else>  <!-- Menu para usuarios que no están en Pending Data -->
        <div class="row">
          
          <div class="col-md-3 col-sm-3 col-xs-12">
            <!-- ESCABEZAZO GRIS DE LA IZQUIERDA -->
            <div class="block-header">
              <h2>{{ item.names }} {{ item.lastNames }}</h2>

              <ul class="actions">
                <li>
                  <a href @click.prevent="$emit('eventToParent','canceled')" title="Volver">
                    <i class="zmdi zmdi-close"></i>
                  </a>
                </li>
              </ul>
            </div>
              <!-- CARD BLANCA DE LA IZQUIERDA -->
            <div class="card">
              <div class="card-body">
                <!-- <img class="img-responsive img-rounded" :src="`/personas/${item._id}/foto`" alt=""> -->
              </div>

              <div class="card-header text-center">
                
                <h2>
                  {{ item.names }} {{ item.lastNames }}
                <!-- <small>{{ item.users[0]?item.users[0].userName:"-" }}</small>-->
                </h2>
              </div>

              <div class="card-body">
                <ul class="my-page-menu">
                  <li :class="currentView=='usuario-view' ? 'active' : ''">
                    <a href @click.prevent="currentView='usuario-view'">
                      <i class="zmdi zmdi-account-circle"></i> Información General
                    </a>
                  </li>
                  <li :class="currentView=='usuario-orgunits' ? 'active' : ''">
                    <a href @click.prevent="currentView='usuario-orgunits'">
                      <i class="zmdi zmdi-balance"></i> Dependencias Vinculadas
                    </a>
                  </li>
                  <li :class="currentView=='usuario-applications' ? 'active' : ''">
                    <a href @click.prevent="currentView='usuario-applications'">
                      <i class="zmdi zmdi-apps"></i> Aplicaciones
                    </a>
                  </li>
                  <li :class="currentView=='usuario-history' ? 'active' : ''">
                    <a href @click.prevent="currentView='usuario-history'">
                      <i class="zmdi zmdi-time"></i> Historial de Cambios
                    </a>
                  </li> 
                  <li v-if="hasPermissions" :class="currentView=='usuarioSettings' ? 'active' : ''">
                    <a href @click.prevent="currentView='usuarioSettings'">
                      <i class="zmdi zmdi-settings"></i> Configuración
                    </a>
                  </li>       
                  <!-- <li :class="currentView=='usuario-accounts' ? 'active' : ''"><a href="" @click.prevent="currentView='usuario-accounts'"><i class="zmdi zmdi-accounts-alt"></i> Cuentas Asociadas</a></li> -->
                  <!-- <li :class="currentView=='usuario-settings' ? 'active' : ''"><a href="" @click.prevent="currentView='usuario-settings'"><i class="zmdi zmdi-settings"></i> Configuración</a></li> -->
                </ul>
              </div>
            </div>
              <!-- BOTON VOLVER -->
              <button @click="$emit('eventToParent','canceled')" class="btn btn-primary btn-block btn-sm waves-effect">Volver a Búsqueda</button>
          </div>
          
        <!-- <div class="col-md-9 col-sm-12 col-xs-12">
            <usuario-view @eventToParent="eventFromChild"></usuario-view>
            <usuario-form @eventToParent="eventFromChild"></usuario-form>
            :dataParent="dataForChild" 
          </div> ver si se usa este o el otro-->
          
        
        <div class="col-md-9 col-sm-12 col-xs-12">
          <component
            :is="currentView"
            :dataParent="dataForChild"
            @eventToParent="eventFromChild"
            keep-alive
          ></component>
        </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { HttpYULI, swal, HTTPFront } from "../../share.js";
import { mapGetters } from "vuex";

import usuarioPendingMenu from "./usuario.pending.menu.vue";
import usuarioView from "./usuario.view.vue";
import usuarioForm from "./usuario.form.vue";
import usuarioOrgunits from "./usuario.orgunits.sublist.vue";
import usuarioAccounts from "./usuario.accounts.sublist.vue";
import usuarioSettings from "./usuario.settings.sublist.vue";
import usuarioApplications from "./usuario.applications";
import usuarioHistory from "./usuario.history.vue";

export default {
  data() {
    return {
      currentView: "usuario-view",
      showForm: false,
      idSelected: "",
      operation: "",
      isEditable: true,
      isInitializing: true,
      isLoadingPermissions: true,
      item: "",
      dataForChild: {
        idSelected: 0,
        operation: "",
        isEditable: true,
        itemSelected: "",
        uncProfiles: [],
      },
      renderPendingDataMenu: false
    };
  },
  computed: {
    ...mapGetters([
      'person',
      'currentUser', 
      'orgUnitsByUser',
      'memberTypes',
    ]),
    hasInitialized() {
      return this.isInitializing || this.isLoadingPermissions;
    },
    hasPermissions() {
      const personOrgUnits = this.orgUnitsByUser?.map(el => el.orgUnit._id);
      const adminOrgUnits = this.currentUser.adminOrgUnits.filter(personInOu => (personInOu.typeMember === this.memberTypes.ADMIN) && (personInOu.permissions?.length > 0)).map(personInOu => personInOu.orgUnit._id);
      return adminOrgUnits.some(adminOu => personOrgUnits.some(personOu => adminOu === personOu));
    },

    isPendingData() {
      if (this.item.state.code === "PD") { this.renderPendingDataMenu = true }
      return this.renderPendingDataMenu;
    }
  },
  components: {
    usuarioPendingMenu,
    usuarioView,
    usuarioOrgunits,
    usuarioAccounts,
    usuarioSettings,
    usuarioForm,
    usuarioApplications,
    usuarioHistory,
  },
  /* props: ["dataParent"], */
  async mounted() {
    this.item = this.person;
    this.dataForChild.idSelected = this.person._id;
    this.dataForChild.isEditable = this.person.isEditable;
    this.dataForChild.itemSelected = this.person;
    this.dataForChild.operation = this.person.operation;
    this.dataForChild.passwordChangeHistory = [];

    if(this.item?.users.length) {
      await this.fetchPasswordChangeHistory();
    }
    
    if (this.dataForChild.idSelected != "") {
      this.getOrgUnits(this.dataForChild.idSelected);
    }

    this.isInitializing = false;

    this.getData();
  },
  methods: {
    async fetchPasswordChangeHistory() {
      const fetchedUsers = await HTTPFront.get("/people/" + this.item._id + '/users');
      this.dataForChild.passwordChangeHistory = fetchedUsers.data[0]?.passwordChangeHistory ?? [];
    },
    async refreshPerson(){
      const response = await HTTPFront.get("/person/" + this.item._id)
      this.item = response.data;
      this.showForm = false;
      this.renderPendingDataMenu = false;
      this.$store.commit("setPerson", this.item);
      //return response;
      /*TODO: MANEJO DE ERRORES*/
    },
    getData() {
      if (this.person.operation === "editItem") this.editItem();
      let self = this;
      let query = "?page=0&limit=1";
    },
    editItem() {
      //this.showForm = true;
    },
    refreshCurrentView(viewComponent = "") {
      //force reload view!
      this.currentView = "";
      this.$nextTick(function() {
        this.currentView = viewComponent;
      });
    },
    async eventFromChild(action = "", params = null) {
      switch (action) {
        case "editItem":
            this.editItem();
            break;

        case "canceled":
            if (this.operation == "addItem") {
                this.$emit("eventToParent", "canceled");
            } else {
                this.showForm = false;
                if (params && params === 'goSearch') {

                this.$emit("eventToParent", "canceled");
                }
                this.renderPendingDataMenu = false;
            }
            break;

        case "saved":
            if (this.operation == "addItem") {
                this.idSelected = params;
                this.operation = "editItem";
            }

            this.dataForChild.idSelected = this.idSelected;
            this.dataForChild.operation = this.operation;
            this.showForm = false;
            this.renderPendingDataMenu = false;
            await this.refreshPerson();
            await this.getOrgUnits(this.item._id);
            this.$emit('eventToParent','added');

            break;
      }
    },
    async getOrgUnits(idPersonaBuscada) {
        const response = await HTTPFront.get("/orgUnitsByUser/" + idPersonaBuscada);
        if (response.status === 200 && response.data) {
          this.$store.commit("setOrgUnitsByUser", response.data);
        }
        this.isLoadingPermissions = false;
    },
  }
};
</script>

<style lang="scss">
</style>