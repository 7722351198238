class User {
    constructor (userInfo) {
        // Copy all userInfo attributes to this instance.
        Object.entries(userInfo).forEach(([k, v]) => { this[k] = v; });
        
        let permissions = userInfo.permisos ?? [];
        this.permissions = permissions.map(p => typeof p === 'string' ? p.toLowerCase() : p);
        if (typeof permission === 'string') {
            permission = permission.toLowerCase();
        }
        this.permissionEquals = typeof permissions[0] === 'object'
            ? (p1, p2) => Object.entries(p1).every(([k, v]) => p2[k] === v)
            : (p1, p2) => p1 === p2;

        let roles = userInfo.roles ?? [];
        this.roles = roles.map(r => r.toLowerCase());
    }

    get displayName () {
        return this.nombre_completo;
    }

    get validUser () {
        return this.hasRole('admin');
    }

    hasPermission (permission) {
        return this.permissions.find(p => this.permissionEquals(p, permission)) !== undefined;
    }

    hasAllPermissions (permissions) {
        return permissions.every(p => this.hasPermission(p));
    }

    hasRole (role) {
        return this.roles.includes(role.toLowerCase());
    }

    hasAllRoles (roles) {
        return roles.every(r => this.hasRole(r));
    }

    hasAuthorities (matchedRecords) {
        return matchedRecords
            .map(record => [record.meta.requiredPermissions || [], record.meta.requiredRoles || []])
            .every(([permissions, roles]) => this.hasAllPermissions(permissions) && this.hasAllRoles(roles));
    }
}

module.exports = User;
