<template>
  <div>
    <usuario-google />
    <usuario-gde />
  </div>
</template>

<script>
import usuarioGoogle from "./usuario.google.vue";
import usuarioGde from "./usuario.gde.vue";
export default {
  data() {
    return {};
  },
  components: {
    usuarioGoogle,
    usuarioGde
  }
};
</script>