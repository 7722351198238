<template>
  <div>
    <div v-if="isInitializing" class="loading">
      <unc-loader></unc-loader>
    </div>

    <div v-else-if="showForm">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12">
          <usuario-menu @eventToParent="eventFromChild"></usuario-menu>
          <!-- :dataParent="dataForChild" -->
        </div>
      </div>
    </div>

    <div v-else class="row">
      <div class="col-md-3 col-sm-12 col-xs-12">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header ch-alt text-center">
                <h2>Criterios de Búsqueda</h2>
              </div>

              <div class="card-body card-padding">
                <form role="form">
                  <div class="form-group fg-line">
                    <label>Nombres o Apellidos:</label>
                    <input
                      type="text"
                      v-model="query.filter.byFullname"
                      class="form-control input-sm"
                      placeholder
                    />
                  </div>

                  <div class="form-group fg-line">
                    <label>Número de Documento:</label>
                    <input
                      type="text"
                      v-model="query.filter.byIdentityNumber"
                      class="form-control input-sm"
                      placeholder="Número de DNI, CUIL..."
                    />
                  </div>

                  <div class="form-group fg-line">
                    <label>Nombre de Usuario:</label>
                    <input
                      type="text"
                      v-model="query.filter.byUserName"
                      class="form-control input-sm"
                      placeholder
                    />
                  </div>

                  <div class="form-group fg-line">
                    <label>Dependencia:</label>
                    <div class="select">
                      <select v-model="query.filter.byOrgUnit" class="form-control tag-select">
                        <option value selected>En toda la UNC</option>
                        <option v-for="item of optionsOrgUnits" :value="item._id">{{ item.name }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group fg-line">
                    <label>Rol:</label>
                    <div class="select">
                        <select v-model="query.filter.byRole" class="form-control tag-select">
                            <option value="" selected>[Todos]</option>
                            <option v-for="item of optionsRole" :value="item.code">{{ item.name }}</option>
                        </select>
                    </div> 
                  </div>

                  <!--
                                <div class="form-group fg-line">
                                    <label>Membresía:</label>
                                    <div class="select">
                                        <select v-model="query.filter.byMembresia" class="form-control tag-select">
                                            <option value="" selected>[Todos]</option>
                                            <option v-for="item of optionsMembresia" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div> 
                                </div>

                                <div class="form-group fg-line">
                                    <label>Dominios:</label>
                                    <div class="select">
                                        <select v-model="query.filter.byDomain" class="form-control tag-select">
                                            <option value="" selected>[Todos]</option>
                                            <option v-for="item of optionsDomains" :value="item.id">{{ item.name }}</option>
                                        </select>
                                    </div> 
                                </div>

                                <div class="form-group fg-line">
                                    <label>Estado:</label>
                                    <div class="select">
                                        <select v-model="query.filter.byState" class="form-control tag-select">
                                            <option value="">[Todos]</option>
                                            <option v-for="item of optionsStates" :value="item._id">{{ item.name }}</option>
                                        </select>
                                    </div> 
                                </div>
                  -->

                  <button
                    @click.prevent="aplicarFiltros()"
                    type="submit"
                    class="btn btn-primary btn-block m-t-10"
                  >
                    <i class="zmdi zmdi-search"></i> Buscar
                  </button>
                  <button
                    @click.prevent="limpiarFiltros()"
                    type="submit"
                    class="btn btn-secundary btn-block m-t-10"
                  >
                    <i class="zmdi zmdi-format-clear"></i> Borrar Filtros
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-9 col-sm-12 col-xs-12">
        <div v-if="welcome && !isLoading" class="card">
          <div class="card-body">
            <div class="text-center">
              <img width="76%" src="../../assets/img/src_assets_img_fondo_unc_3.svg" />
            </div>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-header bgm-gray">
            <h2>{{ listTitle }}</h2>
            <!-- <button v-if="isEditable" @click.prevent="addItem" class="btn bgm-red btn-float waves-effect"><i class="zmdi zmdi-plus"></i></button> -->
          </div>

          <div class="card-body">
            <div v-if="listItems.length === 0 && !isLoading" class="jumbotron text-center">
              <h3>No se han encontrado registros.</h3>
              <p>Verifique los criterios de búsqueda y vuelva a intentarlo.</p>
            </div>

            <div v-else class="my-listview">
              <div class="row my-listview-item th hidden-xs">
                <div class="col-md-5 col-sm-4 col-xs-12">Nombre y Apellido</div>
                <div class="col-md-3 col-sm-3 col-xs-12">Documento</div>
                <div class="col-md-1 col-sm-2 col-xs-12 m-l-5">Rol</div>
                <div class="col-md-1 col-sm-2 col-xs-12 m-l-20">Estado</div>
                <div class="col-md-2 col-sm-1 col-xs-12"></div>
              </div>

              <div  class="row my-listview-item" v-for="(item, index) in listItems" :key="item._id">
                <div style="text-align:left!important" class="col-md-5 col-sm-4 col-xs-12 text-center p-t-5">
                  <h1>{{ item.names }} {{ item.lastNames }}</h1>
                </div>

                <div class="col-md-3 col-sm-3 col-xs-12 p-t-5">
                  <div
                    v-for="identity in item.identities"
                  >{{ identity.identityType.replace('ARG_', '') }} {{ identity.identityNumber }}</div>
                </div>

                <div class="col-md-1 col-sm-2 col-xs-12 text-center p-t-5">
                  <div v-if="item.uncProfiles?.length > 0" >
                    <h1 v-for="profile in formattedProfiles[index]"> {{ profile | toTitleCase }}</h1>
                  </div>
                  <div v-else>
                    <h1 v-if="item.profile"> {{ item.profile.code | toTitleCase }}</h1>
                    <h1 v-else> {{'-'}}</h1>
                  </div>
                </div>

                <div class="col-md-2 col-sm-2 col-xs-12 text-center p-t-5">
                  <h1>{{ item.state?item.state.comment.split('.',1)[0]:'Sin estado' }}</h1>
                </div>

                <div class="col-md-1 col-sm-1 col-xs-12  text-center p-b-1">
                  <ul class="my-actions">
                    <li v-if="isEditable">
                      <a href @click.prevent="editItem(item)">
                        <i class="zmdi zmdi-eye"></i>
                      </a>
                    </li>
                    <!-- <li v-if="isEditable"><a href="" @click.prevent="deleteItem(item._id)"><i class="zmdi zmdi-delete"></i></a></li> -->
                    <!-- <li v-if="!isEditable"><a href="" @click.prevent="viewItem(item)"><i class="zmdi zmdi-chevron-right"></i></a></li> -->
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="isLoading" class="loading">
          <unc-loader></unc-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HttpYULI,
  swal,
  getAutorization,
  HTTPFront
} from "../../share.js";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import usuarioMenu from "./usuario.menu.vue";

export default {
  data() {
    return {
      listTitle: "LISTADO DE USUARIOS UNC",
      listItems: [],
      showForm: false,
      isInitializing: true,
      isEditable: true,
      isLoading: false,
      isScrollActived: true,
      welcome: true,
      query: {
        order: "",
        page: 1,
        offset: 0,
        limit: 50,
        filter: {
          byFullname: "",
          byIdentityNumber: "",
          byState: "",
          byOrgUnit: "",
          byUserName: "",
          byMembresia: "",
          byDomain: "",
          byRole: "",
        }
      },
      dataForChild: {
        idSelected: 0,
        itemSelected: null,
        operation: "",
        isEditable: true
      },
      optionsDocumentTypes: [],
      optionsOrgUnits: [],
      optionsStates: [],
      optionsRole: [],
      optionsDomains: [
        { id: "@unc.edu.ar", name: "@unc.edu.ar" },
        { id: "@mi.unc.edu.ar", name: "@mi.unc.edu.ar" }
      ],
      optionsMembresia: [
        { id: "miembro", name: "Miembro" },
        { id: "administrador", name: "Administrador" }
      ]
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
    formattedProfiles() {
      return this.listItems.map((item) => {
        if (item.uncProfiles && item.uncProfiles.length > 0) {
          return item.uncProfiles.sort();
        } else {
          return ['-'];
        }
      });
    },
  },
  components: {
    usuarioMenu
  },
  mounted() {
    this.scroll();
  },
  created(){
    this.getData();
  },
  methods: {
    getData() {
      let self = this;

      axios
        .all([self.getOrgUnits(), self.getRole()])
        .then(
          axios.spread(function(responseOrgUnits, responseRole) {
            /**/
            self.optionsOrgUnits = responseOrgUnits.data;
            let orgUnits = [];
            self.optionsOrgUnits.forEach(org => {
              orgUnits.push(org);
              orgUnits.push(
                ...org.orgUnitRel
                .map(ouRel => ouRel.orgUnitChild)
                .filter(ou => !orgUnits.find(e => e.code == ou.code))
              );
            });
            orgUnits.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
          self.optionsOrgUnits = orgUnits;
          self.$store.commit("setOrgUnitsByAdmin", self.optionsOrgUnits);

          /*ROLES*/
          self.optionsRole = responseRole.data;
          })
        )
        .then(() => {
          this.isLoading = false;
          this.isInitializing = false;
          //self.getPeople() //VER SI HAY QUE HACERLO O NO
        });
    },
    scroll() {
      window.onscroll = () => {
        if (!this.welcome && !this.showForm) {
          let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
          if (bottomOfWindow && this.isScrollActived) {
            this.getPeople(false);
          }
        }
      };
    },
    getPeople(resetPagination) {
      let self = this;
      this.welcome = false;
      this.isLoading = true;

      if (resetPagination) {
        this.listItems = [];
        this.query.page = 1;
        /* this.query.offset = 0; */
      } else {
        this.query.page++;
        /* this.query.offset = this.query.limit; */
      }

      /*       this.query.page = 1;
      this.query.offset = 50; */
      /*                 this.query.page++;
                this.query.offset = this.query.page * this.query.limit; */
      HTTPFront.post("/peopleByQuery", { query: this.query })
        .then(respuesta => {
          if (respuesta.status === 200) {
            if (respuesta.data.result.length > 0) {
              self.listItems = self.listItems.concat(...respuesta.data.result);
            }
            if (
              respuesta.data.result.length == 0 ||
              respuesta.data.result.length < self.query.limit
            ) {
              this.isScrollActived = false;
            } else {
              this.isScrollActived = true;
            }
            /* console.log("QUERY LIMIT: ", self.query.limit);
            console.log("SCROLL ACTIVATED: ", this.isScrollActived); */
          } else {
            swal.fire({
              title: "Error: " + respuesta.status,
              text: respuesta.statusText,
              icon: "error"
            });
          }
        })
        .catch(error => {
              let status = error.response.status;
              let message = error.response && error.response.data || 'Servicio temporalmente no disponible';
                switch (status) {
                    case 400:
                        swal.fire({title: 'Faltan filtros', text: 'Debe ingresar algún filtro para poder realizar la búsqueda.', icon: 'warning' });
                        break;
                    case 401:
                        swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                        break;
                    case 409:
                        swal.fire({title: 'Algo salió mal', text: 'Algún dato parece tener un error.', icon: 'warning' });
                        break;
                    case 500:
                        swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                        break;
                    default:
                        swal.fire({title: 'Algo salió mal', text: 'Servicio no disponible, por favor intente más tarde.', icon: 'warning' });
                        break;
                }
        })
        .finally(() => {
          this.isLoading = false;
          this.isInitializing = false;
        });
    },
    getOrgUnits() {
      return HTTPFront.get("/orgUnitsTree/" + this.currentUser.id).then(respuesta => {
        this.$store.commit("setOrgUnitsByAdmin", respuesta.data);
        return respuesta;
      });
    },
    async getRole() {
      return await HTTPFront.get("/uncprofiles");
    },
    aplicarFiltros() {
      this.getPeople(true);
    },
    limpiarFiltros(){
      this.query.filter.byFullname = '';
      this.query.filter.byIdentityNumber = '';
      this.query.filter.byUserName = '';
      this.query.filter.byRole = '';
      this.query.filter.byOrgUnit = '';
      this.welcome = true;
    },
    addItem() {
      this.dataForChild.idSelected = 0;
      this.dataForChild.itemSelected = null;
      this.dataForChild.operation = "addItem";
      this.dataForChild.isEditable = true;
      this.showForm = true;
    },
    editItem(item) {
      /*       this.dataForChild.idSelected = item._id;
      this.dataForChild.operation = "editItem";
      this.dataForChild.isEditable = this.isEditable;
      this.dataForChild.itemSelected = item; */
      Object.assign(item, {
        operation: "editItem",
        isEditable: this.isEditable
      });
      this.$store.commit("setPerson", item);
      this.showForm = true;
    },
    viewItem(item) {
      this.dataForChild.idSelected = item._id;
      this.dataForChild.itemSelected = item;
      this.dataForChild.operation = "viewItem";
      this.dataForChild.isEditable = false;
      this.showForm = true;
    },
    deleteItem(idSelected) {
      // let self = this;
      // swal.fire({
      //     title: 'Confirma que desea borrar el elemento?',
      //     text: 'No podrás revertir esta operación!',
      //     icon: 'question',
      //     showCancelButton: true,
      //     confirmButtonText: 'Aceptar',
      //     cancelButtonText: 'Cancelar'
      // }).then(function() {
      //     HTTP.delete('electors/' + idSelected)
      //         .then(response => {
      //             if (response.status === 204 || response.status === 200) {
      //                 self.getPeople(true);
      //             } else {
      //                 swal.fire({title: 'Error: ' + response.status, text: response.statusText, icon: 'error' });
      //             }
      //         })
      //         .catch(e => {
      //             console.log('Error HTTP DELETE electors: ' + e)
      //         });
      //     }, function(dismiss) {
      //         // dismiss can be 'overlay', 'cancel', 'close', 'esc', 'timer'
      //     })
    },

    eventFromChild(action = "", params = null) {
      switch (action) {
        case "canceled":
          this.showForm = false;
          break;

        case "saved":
          this.getPeople(true);
          this.showForm = false;
          break;

        case "added":
          this.getPeople(true);
          break;
      }
    }
  }
};
</script>

<style lang="scss">
</style>