<template>
<div>

    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-show="!isInitializing" class="card animated fadeIn">
        <div class="card-header ch-alt">
            <h2>CAMBIAR CORREO ELECTRÓNICO DE RECUPERACIÓN</h2>

            <ul class="actions">
                <li><a href="" @click.prevent="$emit('eventToParent','canceled')"><i class="zmdi zmdi-close"></i></a></li>
            </ul>
        </div>

        <div v-if="loadingAction" class="text-center p-l-0 p-r-0 loading card-body card-padding">
            <unc-loader></unc-loader>
        </div>
        <div v-else class="card-body card-padding">

            <form @submit.prevent="validateBeforeSubmit">
                <div class="form-group" :class="{'has-error': errors.has('email')}">
                    <div class="fg-line">
                        <label>Nuevo Correo Electrónico de recuperación</label>
                        <p>Enviaremos un correo electrónico a esta dirección para confirmar y completar los cambios.</p>
                        <input v-model="item.email" id="email" name="email" type="text" v-validate="'required|email|isNotYuliEmail'" class="form-control" placeholder="Ingresa el nuevo correo electrónico...">
                    </div>
                    <small v-if="errors.has('email')" class="help-block">{{ errors.first("email") }}</small>
                </div>
                <div class="form-group" :class="{'has-error': errors.has('emailConfirm')}">
                    <div class="fg-line">
                        <label>Repita el Correo Electrónico de recuperación</label>
                        <input v-model="item.emailConfirm" v-validate="{ is: item.email, required, email }" id="emailConfirm" name="emailConfirm" type="text" class="form-control" placeholder="Ingresa nuevamente el correo electrónico...">
                    </div>
                    <small v-if="errors.has('emailConfirm')" class="help-block">{{ errors.first("emailConfirm") }}</small>
                </div>
                <div class="form-group" :class="{'has-error': errors.has('password')}">
                    <div class="input-group">
                        <div class="fg-line text-left">
                            <label for="password">Contraseña de Administrador</label>
                            <input :type="showPassword ? 'text' : 'password'" v-model="item.password" id="password" name="password" v-validate="'required'" class="form-control" placeholder="Ingresá tu contraseña para confirmar..." value=""> 
                        </div>
                        <span @click.prevent="showPassword = !showPassword" class="input-group-addon last">
                            <i class="zmdi zmdi-hc-2x" :class="{'zmdi-eye-off ': !showPassword, 'zmdi-eye': showPassword}"></i>
                        </span>
                    </div>
                    <small v-if="errors.has('password')" class="help-block text-left">{{ errors.first('password') }}</small>
                </div>

                <div class="text-right">
                    <button type="submit" class="btn btn-primary btn-sm m-t-10 waves-effect">Actualizar</button>
                </div>
            </form>

        </div>
    </div>

</div>
</template>

<script>
    import { HttpYULI, swal, HTTPFront } from '../../share.js';
    import axios from 'axios';
    import { mapState, mapGetters } from 'vuex';

    export default {
        data() {
            return {
                idSelected: 0,
                operation: '',
                isInitializing: false,
                item: {
                    email: '',
                    emailConfirm: '',
                    password: ''
                },
                showPassword: false,
                passwordFieldType: 'password',
                loadingAction: false,
            }
        },
        computed: {
            ...mapState([
                'isLoading'
            ]),
            ...mapGetters([
                'person',
            ])
        },
        components: {
        },
        props: [
            'dataParent'
        ],
        mounted() {
            this.idSelected = this.dataParent.idSelected;
            this.operation = this.dataParent.operation
            this.isEditable = this.dataParent.isEditable;
        },
        methods: {
            validateBeforeSubmit(e) {
                this.$validator.validateAll().then(() => {
                    if (!this.errors.any()) {
                        this.save();
                    } else {
                        console.log('No Validated: ', JSON.stringify(this.errors.items)); 
                    }
                }).catch(() => {
                    swal.fire({title: 'Algo salió mal', text: 'El correo electrónico de recuperación no fue actualizado.', icon: 'warning' });
                });

            },
            save() {
                this.loadingAction = true;

                let self = this;
                this.$store.commit('setLoading',true);

                let bodyToSend = {
                    email: self.item.email,
                    password: self.item.password
                }


                HTTPFront.put(`personas/${self.person._id}/mail`, bodyToSend)
                .then(response => {
                    if (response.status === 200) {
                        // Success
                        swal.fire({title: '', html: `Enviamos un correo electrónico a <strong>${self.item.email}</strong>. Informá al usuario que revise su casilla y siga las instrucciones para continuar con la actualización de su correo electrónico.`, icon: 'info' });
                        this.$emit('eventToParent','saved');
                    }
                }).catch(error => {
                    let status = error.response && error.response.data.code || error.response.status;
                    let message = error.response && error.response.data.message || 'Servicio temporalmente no disponible';

                    switch (status) {
                        case 401:
                            swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                            break;
                        case 500:
                            swal.fire({title: 'Algo salió mal', text: message, icon: 'warning' });
                            break;
                        default:
                            swal.fire({title: 'Correo electrónico no actualizado', text: message, icon: 'warning' });
                            break;
                    }
                }).finally(() => {
                    this.$store.commit('setLoading',false);
                    this.loadingAction = false;
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

    .input-group .fg-line {
        padding: 0;
    }

    .input-group .fg-line:after {
        left: 0px;
    }

    .input-group .fg-line.fg-toggled:after {
        width: calc(100% - 20px);
    }

    .input-group-addon {
        cursor: pointer;
    }

</style>