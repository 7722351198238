<template>
<div>

    <div class="card animated fadeIn">
        <div class="card-header" :class="this.person.state.code === 'A' ? 'bgm-green' : 'bgm-gray'">
            <h2>{{titulo}} </h2>
            <div>
                <!-- <button v-if="isEditable" @click.prevent="editItem()" class="btn bgm-blue btn-float waves-effect"><i class="zmdi zmdi-edit"></i></button> -->
            </div>
        </div>

        <div class="card-body card-padding">

            <div class="my-view-block">
                <ul>
                    <li>
                        <label>Nombres y Apellido</label><br>
                        {{ item.names }} {{ item.lastNames }}
                    </li>

                    <li v-for="identity in item.identities" :key="identity.identityType.code">
                        <label>{{ identity.identityType.replace('ARG_', '') }}</label><br>
                        {{ identity.identityNumber }}
                    </li>

                    <li>
                        <label>Correo Electrónico de Recuperación</label><br>
                        <span v-for="(email,index) in item.emails" :key="email.mail">
                            <span v-if="index!='nextMainEmail'">{{ email.mail }} <br></span>
                        </span>
                    </li>

                    <li>
                        <label>Nombre de Usuario</label><br>
                        {{ item.users?.length > 0 ? item.users[0]?.userName : 'Sin Usuario UNC' }}

                    </li>

                    <li>
                        <label>Estado</label>
                        <a
                            style="font-size: 20px;margin-left: 5px;"
                            data-toggle="popover"
                            data-placement="right"
                            tabindex="0"
                            data-original-title="Código de Colores de Estados">
                            <i class="zmdi zmdi-pin-help"></i>
                        </a>
                        <br>
                        <small>
                            <span :class="this.person.state.code === 'A' ? 'badge bgm-green' : (this.person.state.code === 'S' || this.person.state.code ==='I')? 'badge bgm-red':'badge bgm-indigo'">{{ item.estado?.split('.',1)[0] }}</span>
                        </small>
                        <br>
                        <span>{{ item.estado?.split('.').slice(1).join('.').trim() }}</span>
                    </li>

                    <li>
                        <label>Rol al momento del registro</label><br>
                        <span> {{ item.perfil ? item.perfil : 'Sin rol asociado' }} </span>
                    </li>
                                     
                    <li>
                        <label>Fecha de Registro de Persona</label><br>
                        {{ item.personDate }}
                    </li>

                    <li>
                        <label>Fecha de la última modificación de la contraseña</label><br>
                        {{ item.lastPasswordChangeTimestamp | moment("DD/MM/YYYY HH:mm") }}
                    </li>

                    <li v-if="validator">
                        <label>Administrador que verificó la información</label><br>
                        {{ validator.names }} {{ validator.lastNames }} - {{ validator.userName }}<br>
                    </li>

                    <span class="badge" :class="item.personState && item.personState.code === 'A' ? 'bgm-green' : 'bgm-red'" >{{ item.personState?.name }}</span>
                </ul>
            </div>

        </div>
    </div>
</div>
</template>

<script>
    import { HTTPFront, swal } from "../../share.js";
    import { mapGetters } from "vuex";
    import { createPopper  } from "@popperjs/core";
    import VueMoment from 'vue-moment';

export default {
    data() {
        return {
            titulo: 'INFORMACIÓN GENERAL',
            item: {},
            isEditable: false,
            validator:{},
        }
    },
    computed: {
        ...mapGetters(["person", "uncProfiles"]),
        formattedUncProfiles() {
            const uncProfiles = this.item?.uncProfiles ?? [];

            return uncProfiles.sort().map(code => {
                const profile = this.uncProfiles.find(p => p.code === code);
                return profile?.name ?? code;
            });
        }
    },    
    props: [
        'dataParent'
    ],
    async created() {
        /*this.item = this.dataParent.itemSelected;
        this.isEditable = this.dataParent.isEditable; */
        await this.getPersonData();
        this.item = this.person;
        
        /*Formateo de fecha de creación del registro de la persona*/ 
        let timestamp = this.item._id.toString().substring(0,8);
        let date = new Date( parseInt( timestamp, 16 ) * 1000 );
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        this.item.personDate = day +'/'+month+'/'+year;

        this.isEditable = this.person.isEditable;
        this.item.estado = this.person.state ? this.person.state.comment : "Sin estado asociado";
        this.item.perfil = this.person.profile ? this.person.profile.name : "Sin rol asociado";

        const lastPasswordChange = this.dataParent.passwordChangeHistory?.[0];
        this.item.lastPasswordChangeTimestamp = lastPasswordChange?.timestamp ?? 'Sin información';

        if (this.item.validator?.match(/^[0-9a-fA-F]{24}$/)) { 
            this.getValidator(); 
        }

        //Popover
        $(function () {
            $('[data-toggle="popover"]').popover({
                content: '<p><span class="badge bgm-green">Estado</span><span> El Usuario puede utilizar las aplicaciones UNC, previo habilitación de las mismas.</span>'+
                        ' <br>'+
                        '<span class="badge bgm-red">Estado</span><span> Actividad a realizar por el Administrador.</span><br>'+
                        '<span class="badge bgm-indigo">Estado</span><span> Actividad a realizar por la persona que está creando su Usuario.</span>'+
                        '</p>',
                html : true
            })
        })

    },
    methods: {
        editItem() {
            this.$emit('eventToParent','editItem')
        },
        async getValidator(){
            try {
                const response = await HTTPFront.get("/person/" + this.item.validator);
                this.validator = response.data;
                let adminData = response.data.users.find(el => { return el.userName.includes('@unc') })
                this.validator.userName = adminData.userName;
            } catch (err) {
                this.validator.userName = 'No se pudo encontrar información del administrador que validó la persona';
            }
        },
        async getPersonData() {
            try {
                const response = await HTTPFront.get("/person/" + this.person._id);
                this.$store.commit("setPerson", response.data);
            } catch (error) {
                let status = error?.response && error?.response?.data?.code || error?.response?.status;
                let message = error?.response && error?.response?.data?.message || 'Servicio temporalmente no disponible';
                switch (status) {
                    case 401:
                        await swal.fire({title: 'Acceso denegado', text: 'No posee permisos para acceder a este servicio.', icon: 'warning' });
                        break;
                    case 500:
                        await swal.fire({title: 'Algo salió mal', text: message, icon: 'warning' });
                        break;
                    default:
                        await swal.fire({title: 'Error al obtener información de la persona', text: message, icon: 'warning' });
                        break;
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>

    .my-listview-item {
        padding-top: 15px !important;
        padding-bottom: 10px !important;
    }
    #tooltip {
     /* ... */
        display: none;
}

#tooltip[data-show] {
  display: block;
}

 #tooltip {
        background: #333;
        color: white;
        font-weight: bold;
        padding: 4px 8px;
        font-size: 13px;
        border-radius: 4px;
      }
.uncProfiles {
    display: block;
}
</style>
