import Vue from 'vue';
import User from './User';
import axios from 'axios';

const httpClient = axios.create({
    baseURL: '/',
    headers: { 'x-client-ajax': 1 },
});

export default new Vue({
    data () {
        return {
            loading: false,
            isAuthenticated: false,
            user: null,
            error: null,
            ready: false,
            forceLogin: false,
        };
    },
    computed: {
        validUser () {
            return this.user?.validUser;
        }
    },
    methods: {
        logout () {
            this.isAuthenticated = false;
            this.user = {};
        },
        async login () {
            if (this.ready) {
                return;
            }
            this.loading = true;
            try {
                const user = (await httpClient.get('/userInfo')).data;
                this.user = new User(user);
                this.isAuthenticated = true;
            } catch (error) {
                const status = error.response?.status;
                if (status === 418) {
                    this.forceLogin = true;
                } else if (status === 403) {
                    this.isAuthenticated = true;
                } else {
                    this.error = error;
                }
            } finally {
                this.loading = false;
                this.ready = true;
            }
        }

    },
});
