<template>
<div>
    
    <div v-if="isInitializing" class="loading">
        <unc-loader></unc-loader>
    </div>

    <div v-else class="row animated fadeIn">
    
        <div class="col-md-12 col-sm-12 col-xs-12">
            
            <div v-if="item.users.length === 0" class="jumbotron text-center">
                <h3>No se han encontrado cuentas asociadas.</h3>
            </div>

            <div v-else>
                <div class="card">
                    <div class="card-header ch-alt">
                        <h2>{{ listTitle }}</h2>
                    </div>
                        
                    <div class="card-body">
                        <div class="my-listview">
                            <div class="row my-listview-item th hidden-xs">
                                <div class="col-md-4 col-sm-4 col-xs-12">Nombre de Usuario</div>
                                <div class="col-md-2 col-sm-2 col-xs-12">Entorno</div>
                                <div class="col-md-6 col-sm-6 col-xs-12">Alias</div>
                            </div>

                            <div class="row my-listview-item" v-for="user in item.users" :key="user._id">
                                <div class="col-md-4 col-sm-4 col-xs-12"><h1>{{ user.userName }}</h1></div>
                                <div class="col-md-2 col-sm-2 col-xs-12">{{ user.homeSystem }}</div>
                                <div class="col-md-6 col-sm-6 col-xs-12"> {{ user.alias ? user.alias.join(', ') : '' }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div v-if="isLoading" class="loading">
                    <unc-loader></unc-loader>
                </div>
            </div>

        </div>
        
    </div>

</div>
</template>

<script>
    import { HttpYULI, swal } from '../../share.js';
    import axios from 'axios';

    export default {
        data() {
            return {
                listTitle: 'CUENTAS ASOCIADAS',
                listItems: [],
                showForm: false,
                isInitializing: true,
                isEditable: true,
                isLoading: false,
                isScrollActived: true,
                item: '',
                query: {
                    order: 'titulo',
                    page: 0,
                    offset: 0,
                    limit: 25
                },
                dataForChild: {
                    idSelected: 0,
                    operation: '',
                    isEditable: true
                }
            }
        },
        props: [
            'dataParent'
        ],
        components: {
        },
        mounted() {
            this.item = this.dataParent.itemSelected;
            this.isEditable = this.dataParent.isEditable;

            this.isInitializing = false;

            // this.scroll();
            // this.getData();
        },
        methods: {
            getData() {
                let self = this;
                self.getData(true);
            },
            scroll() {
                window.onscroll = () => {
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

                    if (bottomOfWindow && this.isScrollActived) {
                        this.getData(false);
                    }
                };
            },
            getData(resetPagination = false){
                // let self = this;

                // this.isLoading = true;

                // if (resetPagination) {
                //     this.listItems = [];
                //     this.query.page = 0;
                //     this.query.offset = 0;
                // }

                // let query = '?idAlumno=' + self.item.id;
                // query += (self.query.order != "") ? '&order=' + self.query.order : '';
                // query += (self.query.offset != "") ? '&page=' + self.query.offset : '';
                // query += (self.query.limit != "") ? '&limit=' + self.query.limit : '';

                // // Incremento Paginación
                // this.query.page++;
                // this.query.offset = this.query.page * this.query.limit;
            
                // HttpYULI.get('endpoint' + query)
                // .then(response => {
                //     if (response.status === 200) {

                //         if (response.data.length > 0) {
                //             self.listItems = self.listItems.concat(...response.data);
                //         }

                //         if (response.data.length == 0 || response.data.length < self.query.limit) {
                //             this.isScrollActived = false;                            
                //         } else {
                //             this.isScrollActived = true;
                //         }

                //     } else {
                //         swal.fire({title: 'Error: ' + response.status, text: response.statusText, icon: 'error' });
                //     }
                // }).catch(e => {
                //     console.log('Error HttpYULI GET alumnos-proyectos: ' + e);
                // }).finally(() => { 
                //     this.isLoading = false;
                //     this.isInitializing = false;
                // }); 

                this.isLoading = false;
                this.isInitializing = false;
            }
        }
    }
</script>

<style lang="scss">
    
</style>